<template>
  <div class="alert" :class="'alert-' + type" style="padding: 15px 25px" v-show="isVisible">
    <button type="button" class="close" v-show="hasCloseButton" @click="hide()" style="margin: -5px">×</button>
    <span
      style="color: inherit"
      class="glyphicon"
      :class="{
        'glyphicon-info-sign': type == 'info',
        'glyphicon-ok-sign': type == 'success',
        'glyphicon-exclamation-sign': type == 'warning',
        'glyphicon-remove-sign': type == 'danger',
      }"
    >
    </span>
    <span style="font-weight: bold" v-if="title">{{ " " + $t(title) }}</span>
    <hr style="margin: 10px -10px 15px" />
    <div v-if="isArray(body)">
      <p v-for="(bodyItem, index) in body" :key="index">{{ $t(bodyItem) }}</p>
    </div>
    <div v-else-if="body">
      <span>{{ $t(body) }}</span>
    </div>
  </div>
</template>
<script>
import AlertMessageEventBus from "@/components/AlertMessage/AlertMessageEventBus.js";

export default {
  name: "AlertMessage",
  props: {
    alertTitle: {
      type: String,
      required: false,
    },
    alertBody: {
      type: [String, Array],
      required: false,
    },
    alertType: {
      type: String,
      required: false,
      default: "info",
      validator: function (value) {
        return ["info", "success", "warning", "danger"].indexOf(value) !== -1;
      },
    },
    closeButtonVisible: {
      type: Boolean,
      required: false,
      default: true,
    },
    msTimeOut: {
      type: Number,
      required: false,
    },
    scrollAutomatically: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      title: this.alertTitle,
      body: this.alertBody,
      type: this.alertType,
      hasCloseButton: this.closeButtonVisible,
      timeout: this.msTimeOut,
      isVisible: false,
      closeTimeout: null,
      scrollToTop: true,
    };
  },
  mounted() {
    AlertMessageEventBus.on("show", (message) => {
      this.show(message);
    });

    AlertMessageEventBus.on("hide", () => {
      this.hide();
    });
  },
  methods: {
    hide() {
      this.isVisible = false;
      if (this.closeTimeout) {
        clearTimeout(this.closeTimeout);
      }
    },
    show(message) {
      // Hide previous alert (if any)
      this.hide();

      // Set new values
      this.title = message.title;
      this.body = message.body;
      this.type = message.type || "info";
      this.hasCloseButton = message.hasCloseButton || true;
      this.timeout = message.timeout;
      this.scrollToTop = message.scrollToTop || true;

      // Show alert
      this.isVisible = true;
      if (this.timeout) {
        this.closeTimeout = setTimeout(() => {
          this.hide();
        }, this.timeout);
      }

      // Scroll automatically
      if (this.scrollToTop) {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }
    },
    isArray(value) {
      return value && Array.isArray(value);
    },
  },
};
</script>