<template>
  <div class="container padded-in">
    <h2 class="color-blue">
      <span>{{ $t("GENERICS.SATISFACTION_SURVEY") }}</span>
    </h2>
  </div>

  <div class="separator"></div>

  <div>
    <form class="padded-in form-group" @submit.prevent="saveClaimFeedback()">
      <p>
        <span class="bold">{{ $t("GENERICS.SATISFACTION_SURVEY_QUESTIONS.QUESTION_1") }}</span
        ><br />
        <label style="font-weight: normal" class="pointer"
          ><input type="radio" name="usefulInfo" v-model="claimFeedback.questions.USEFUL_INFORMATION" value="YES" @click="updateQuestionsClaimFeedback('USEFUL_INFORMATION_COMMENTS', null)" required /><span>{{
            $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.YES")
          }}</span></label
        ><br />
        <label style="font-weight: normal" class="pointer"
          ><input type="radio" name="usefulInfo" v-model="claimFeedback.questions.USEFUL_INFORMATION" value="NO" /><span>{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.NO") }}</span></label
        >
        <br />
      </p>
      <p v-if="claimFeedback.questions.USEFUL_INFORMATION == 'NO'">
        <br />
        <span class="bold">{{ $t("GENERICS.SATISFACTION_SURVEY_QUESTIONS.QUESTION_1_COMMENTS") }}</span
        ><br />
        <textarea class="form-control" v-model="claimFeedback.questions.USEFUL_INFORMATION_COMMENTS" required></textarea>
        <br />
      </p>
      <p>
        <span class="bold">{{ $t("GENERICS.SATISFACTION_SURVEY_QUESTIONS.QUESTION_2") }}</span
        ><br />
      </p>
      <div>
        <div class="text-center text-success padded-in inline-block-element pointer" v-bind:class="{ bordered: claimFeedback.questions.SATISFACTION == 5 }" @click="updateQuestionsClaimFeedback('SATISFACTION', 5)">
          <i class="fa fa-3x fa-smile-o pointer visible-xs-inline-block" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_SATISFIED')"></i>
          <i class="fa fa-5x fa-smile-o pointer hidden-xs" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_SATISFIED')"></i>
          <p class="hidden-xxs">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_SATISFIED") }}</p>
        </div>
        <div class="text-center text-info padded-in inline-block-element pointer" v-bind:class="{ bordered: claimFeedback.questions.SATISFACTION == 4 }" @click="updateQuestionsClaimFeedback('SATISFACTION', 4)">
          <i class="fa fa-3x fa-smile-o pointer visible-xs-inline-block" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.SATISFIED')"></i>
          <i class="fa fa-5x fa-smile-o pointer hidden-xs" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.SATISFIED')"></i>
          <p class="hidden-xxs">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.SATISFIED") }}</p>
        </div>
        <div class="text-center padded-in inline-block-element pointer" v-bind:class="{ bordered: claimFeedback.questions.SATISFACTION == 3 }" @click="updateQuestionsClaimFeedback('SATISFACTION', 3)">
          <i class="fa fa-3x fa-meh-o pointer visible-xs-inline-block" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.NORMAL')"></i>
          <i class="fa fa-5x fa-meh-o pointer hidden-xs" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.NORMAL')"></i>
          <p class="hidden-xxs">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.NORMAL") }}</p>
        </div>
        <div class="text-center text-warning padded-in inline-block-element pointer" v-bind:class="{ bordered: claimFeedback.questions.SATISFACTION == 2 }" @click="updateQuestionsClaimFeedback('SATISFACTION', 2)">
          <i class="fa fa-3x fa-frown-o pointer visible-xs-inline-block" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.UNSATISFIED')"></i>
          <i class="fa fa-5x fa-frown-o pointer hidden-xs" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.UNSATISFIED')"></i>
          <p class="hidden-xxs">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.UNSATISFIED") }}</p>
        </div>
        <div class="text-center text-danger padded-in inline-block-element pointer" v-bind:class="{ bordered: claimFeedback.questions.SATISFACTION == 1 }" @click="updateQuestionsClaimFeedback('SATISFACTION', 1)">
          <i class="fa fa-3x fa-frown-o pointer visible-xs-inline-block" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_UNSATISFIED')" @click="updateQuestionsClaimFeedback('SATISFACTION', 1)"></i>
          <i class="fa fa-5x fa-frown-o pointer hidden-xs" aria-hidden="true" :title="$t('GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_UNSATISFIED')"></i>
          <p class="hidden-xxs">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_UNSATISFIED") }}</p>
        </div>
      </div>
      <p v-if="claimFeedback.questions.SATISFACTION">
        <br />
        <span class="bold">{{ $t("GENERICS.SATISFACTION_SURVEY_QUESTIONS.QUESTION_2_COMMENTS") }}</span>
        <br />
        <textarea class="form-control" v-model="claimFeedback.questions.SATISFACTION_COMMENTS"></textarea>
        <span class="small"
          ><i aria-hidden="true">{{ $t("GENERICS.SATISFACTION_SURVEY_MESSAGE_USAGE") }}</i></span
        >
      </p>
      <br />
      <p>
        <span class="bold">{{ $t("GENERICS.SATISFACTION_SURVEY_QUESTIONS.QUESTION_3") }}</span
        ><br />
      </p>
      <div class="clearfix">
        <div class="hidden-xxs text-center would-recommend-text-2 pull-left small">
          <span class="color-blue">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_LIKELY") }}</span>
        </div>
        <div v-for="n in wouldRecommendOptions.slice().reverse()" :key="n" class="pull-left would-recommend-number pointer" @click="updateQuestionsClaimFeedback('WOULD_RECOMMEND', n)">
          <span class="fa fa-stack" v-bind:class="{ 'text-danger': n < 7, 'text-success': n > 8 }">
            <i aria-hidden="true" class="fa fa-stack-2x" v-bind:class="claimFeedback.questions.WOULD_RECOMMEND == n ? 'fa-circle' : 'fa-circle-thin'"></i>
            <span class="fa-stack-1x" v-bind:class="{ 'color-white': claimFeedback.questions.WOULD_RECOMMEND == n }">{{ n }}</span>
          </span>
        </div>
        <div class="hidden-xxs text-center would-recommend-text-1 pull-left small">
          <span class="color-blue">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_UNLIKELY") }}</span>
        </div>
      </div>
      <div class="clearfix visible-xxs-block would-recommend-block-sm">
        <div class="text-center would-recommend-text-2 pull-left small">
          <span class="color-blue">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_LIKELY") }}</span>
        </div>
        <div class="text-center would-recommend-text-1 pull-right small">
          <span class="color-blue">{{ $t("GENERICS.SATISFACTION_SURVEY_ANSWERS.VERY_UNLIKELY") }}</span>
        </div>
      </div>
      <br />
      <br />
      <p>
        <span>{{ $t("GENERICS.SATISFACTION_SURVEY_PRIVACY_POLICY_MESSAGE") }}&nbsp;</span>
        <a class="color-blue" href="https://www.telefonicainsurance.es/politica-de-privacidad/" rel="noopener noreferrer" target="_blank">{{ $t("GENERICS.SATISFACTION_SURVEY_PRIVACY_POLICY") }}</a>
      </p>
      <input class="btn btn-success btn-lg" type="submit" :value="$t('GENERICS.SUBMIT')" :disabled="!claimFeedback.questions.SATISFACTION || !claimFeedback.questions.WOULD_RECOMMEND || !submitAvailable" />
    </form>
  </div>
</template>

<script>
export default {
  name: "ClaimInfoFeedback",
  props: ["claim"],
  data() {
    return {
      claimFeedback: {
        hashkey: this.$route.params.hashkey,
        questions: {
          USEFUL_INFORMATION: null,
          USEFUL_INFORMATION_COMMENTS: null,
          SATISFACTION: null,
          SATISFACTION_COMMENTS: null,
          WOULD_RECOMMEND: null,
          EVENT: null,
        },
      },
      wouldRecommendOptions: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      submitAvailable: true,
    };
  },
  mounted() {
    this.updateQuestionsClaimFeedback("EVENT", "event_" + parseInt(this.claim.lastEvent));
  },
  methods: {
    saveClaimFeedback() {
      this.submitAvailable = false;

      this.axios
        .post(`${process.env.VUE_APP_IBIZA_ENDPOINT}/open-api/feedback/`, this.claimFeedback)
        .then(() => {
          let body;
          const title = "MESSAGES.SUCCESS.DATA_SUBMITTED_SIMPLE";
          const type = "info";

          if (this.claimFeedback.questions.SATISFACTION == "1" || this.claimFeedback.questions.SATISFACTION == "2") {
            body = ["MESSAGES.SUCCESS.DATA_SUBMITTED_THANKS", "MESSAGES.ERROR.DATA_SUBMITTED_UNSATISFIED", "MESSAGES.SUCCESS.DATA_SUBMITTED_SERVICE_THANKS"];
          } else if (this.claimFeedback.questions.SATISFACTION == "3" || this.claimFeedback.questions.SATISFACTION == "4" || this.claimFeedback.questions.SATISFACTION == "5") {
            body = ["MESSAGES.SUCCESS.DATA_SUBMITTED_THANKS", "MESSAGES.SUCCESS.DATA_SUBMITTED_SATISFIED", "MESSAGES.SUCCESS.DATA_SUBMITTED_SERVICE_THANKS"];
          }

          this.$alert.show({ title: title, body: body, type: type, hasCloseButton: true });
        })
        .catch((error) => {
          this.submitAvailable = true;
          let body;
          const title = "MESSAGES.ERROR.TITLE";
          const type = "danger";
          if (error.status === 403) {
            body = "MESSAGES.ERROR.403";
          } else {
            body = "MESSAGES.ERROR.ON_SAVE_CHANGES";
          }
          this.$alert.show({ title: title, body: body, type: type, hasCloseButton: true, timeout: 8000 });
        });
    },

    updateQuestionsClaimFeedback(key, value) {
      this.claimFeedback.questions[key] = value;
    },
  },
};
</script>