export default {
  "true": "Ja",
  "false": "Nein",

  "ERROR": {

    "DATA_NOT_FOUND": "The following serial numbers/claim references haven't been found",
    "INVALID_DATA": "Die eingefügten Daten haben kein gültiges Format",
    "VALID_FORMATS_1": "Gültige Zeichen: a-z, A-Z y 0-9 (außer ñ, Ñ, ç y Ç)",
    "VALID_FORMATS_2": "Gültige Trennzeichen: -, /, comma, blank space, tabulation, line break",
    "VALID_FORMATS_3": "z.B: aaa,bbb ccc-ddd /eee",
    "MIXED_SEARCH_DATA_TYPES": "It's been impossible to complete the requested search. Please check that the entered information corresponds to a single type of data (References or Serial numbers)",
    "NO_REPAIRS_FOR_IMEI": "No claims found for the following reference number",
    "TITLE": "An error has occurred",
    "ON_SAVE_CHANGES": "Could not save changes with the entered information",
    "403": "You are not authorized to perform this action",
    "MAX_1000ITEM_PER_EXCEL_FILE": "Too many rows to generate the excel file. Máx. 1000 rows",
    "DATA_STILL_DOWNLOADING": "Please wait while data is retrieved",

    "BASIC": {
      "ERROR_TITLE": "The info requested is not available",
      "ERROR_MESSAGE_A": "Unfortunately we can not provide the requested information about the claim. Please contact the administrator",
      "ERROR_MESSAGE_B": "The information is not available, if you need help please contact the insurance program management team: ",
      "ERROR_MESSAGE_CORONAVIRUS_DELAY": "Due to the alarm status of COVID-19 we are having problems with transportation in your zip code. As soon as the situation returns to normal, we can give you the service that we usually offer. We regret the inconvenience that this situation may cause"
    }
  },

  "CLAIM_CREATED": "Claim Created",
  "PICKUP_PROCESS": "Device on Pickup Process",
  "RECEIVED_HUB": "Device Received in Warehouse",
  "PICKUP_SUCCEEDED": "Pickup succeeded",
  "PICKUP_COMPLETED": "Pickup completed",
  "RECEIVED_HUB_INCIDENCE": "Device received with an incidence",
  "CLAIM_ACCEPTED": "Claim Accepted",
  "CLAIM_REJECTED": "Claim Rejected",
  "RECEIVED_LAB": "Device on Technical Service",
  "REJECTED_LAB": "Device rejected on Technical Service",
  "TERMINAL_REPAIRED": "Terminal repaired",
  "ESCALATED_HIGHER_LEVEL": "Escalated to Higher Level",
  "UNDER_TECHNICAL_ANALYSIS": "Terminal Under Technical Analysis",
  "MATERIAL_REQUESTED_PROVIDER": "Material Requested to Provider",
  "PENDING_MATERIAL": "Pending material",
  "READY_DELIVERY": "Device Ready for Delivery",
  "RETURN_PROCESS": "Claim in return process",
  "RETURN_REQUESTED": "Return Requested to transport",
  "RETURN_COMPLETED": "Device Delivered to Client",
  "PENDING_PIECES": "Pending pieces, will be received shortly",
  "PENDING_STOCK": "Terminal not repaired. Pending stock",

  "CONTACT_INFO": "For more information contact the administrator",


  "GENERICS": {

    "NONE_OPT": "-- keiner --",
    "FILTER": "Filter",
    "OPTIONS": "Optionen",
    "BACK": "Zurück",
    "DETAILS": "Einzelheiten",
    "NEW": "Neu",
    "EDIT": "Bearbeiten",
    "SAVE": "Speichern",
    "DELETE": "Ausschalten",
    "IS_ACTIVE": "Aktiv",
    "DELETED": "Inaktiv",
    "INFO": "Information",
    "PREVIOUS": "Previous",
    "NEXT": "Next",
    "CANCEL": "Cancel",
    "DOWNLOAD": "Download",
    "RESET": "Reset",

    "GLOBAL_SEARCH": "Global search",

    "DASHBOARD": "Dashboard",
    "SEARCH": "Search",

    "CLAIM": "Claim",
    "CLAIMS": "Claims",
    "TOTAL_CLAIMS": "Total claims",

    "EVENTS": "Events",
    "EVENT": "Event",
    "CODE": "Code",
    "DESCRIPTION": "Description",
    "AUX_INFO": "Aux. info.",
    "DATE": "Date",

    "PICKUP_INFO": "Pickup info.",
    "RETURN_INFO": "Return info.",
    "POST_CODE": "Post code",
    "TRANSACTED_BY": "Transacted by",
    "EXTRA_INFO": "Additional info.",
    "PENINSULA": "(PENINSULA)",
    "CANARY_ISLANDS": "(CANARY ISLANDS)",

    "SERIAL_NUMBER": "Serial number",
    "CLAIM_REF": "Claim ref",
    "ORDER_REF1": "Order ref",
    "ORDER_REF2": "Req ref",
    "POLICY_TYPE": "Policy type",
    "COMMENTS": "Comments",
    "ID_POLICY": "Policy",
    "CREATION_TS": "Creation date",
    "CREATION_SN": "Creation Serial N.",
    "DEV_DESCRIPTION": "Device description",
    "DEV_IN_SN": "Serial N. in",
    "MODEL_IN": "Model in",
    "DEV_OUT_SN": "Serial N. out",
    "MODEL_OUT": "Model out",
    "CLAIM_TYPE": "Order Type",
    "DEVICE": "Device",
    "MANUFACTURER": "Manufacturer",
    "MODELNAME": "Model",
    "AFTERDATE": "Event after",
    "BEFOREDATE": "Event before",
    "ONLY_LAST_EVENT": "Filter by last event",

    "NEW_CLAIM": "New Claim",
    "NEW_EVENT": "New Event",
    "CLAIM_HAS_COST": "Claim Cost",
    "COST_TYPE": "Cost Type",
    "TOTAL_COST": "Total Cost",
    "COSTS": "Costs",
    "DOCUMENTS": "Documents",
    "ADD_COST": "Add Cost",

    "SERVICE_VENDOR": "Service vendor",
    "SERVICE_TYPE": "Service type",

    "ADD_DOCUMENT": "Add Document",
    "NAME": "Name",
    "SELECT_FILE": "Select File",

    "DOWNLOAD_EXCEL": "Download excel",

    "CLIENT_VIEW": "Client view",
    "SEE_AS_CLIENT": "See claim details as client",

    "CLAIM_STATUS": "Claim status",
    "CURRENT_STATUS": "Current status",
    "STATE": "State",
    "CLAIM_CODE": "Claim code",
    "VOLUME": "Volume",
    "CLAIM_CREATED_STATUS": "@:CLAIM_CREATED",
    "CLAIM_PICKUP_STATUS": "@:PICKUP_PROCESS",
    "CLAIM_REPAIR_STATUS": "@:RECEIVED_LAB",
    "CLAIM_READY_RETURN_STATUS": "@:READY_DELIVERY",
    "CLAIM_DELIVERED_STATUS": "@:RETURN_COMPLETED",

    "CLIENT_INFO": "Client info",
    "CLIENT_NAME": "Name",
    "PHONE_NUMBER": "Phone number",
    "EMAIL": "Email",
    "ADDRESS": "Address",
    "CITY": "City",
    "PROVINCE": "Province",
    "COUNTRY": "Country",
    "TAX_CODE": "Tax code",

    "CLAIM_TRACKING": "Claim Tracking",
    "DATE_TIME": "Date",
    "DETAILED_INFO": "Detailed information",
    "DEVICE_DESCRIPTION": "Device description",
    "DAYS": "days",

    "CLAIM_LIST_STATUS": "Claim status list",
    "ADD_EVENT": "Add event",

    "SATISFACTION_SURVEY": "Satisfaction survey",
    "SATISFACTION_SURVEY_QUESTIONS": {
      "QUESTION_1": "Has the information received during the process been useful?",
      "QUESTION_1_COMMENTS": "We would like to know how to improve the information we give you to offer you a better service",
      "QUESTION_2": "Are you satisfied with the service provided during the sinister of your terminal?",
      "QUESTION_2_COMMENTS": "We would like to know more about your experience and opinion to offer you a better service",
      "QUESTION_3": "How likely are you to recommend Seguro Móvil to a family member or friend?"
    },
    "SATISFACTION_SURVEY_MESSAGE_USAGE": "* Your comment may be published on our website, but in no case will your personal information be published",
    "SATISFACTION_SURVEY_PRIVACY_POLICY_MESSAGE": "By sending this survey I confirm that I have read and accept the",
    "SATISFACTION_SURVEY_PRIVACY_POLICY": "Privacy Policy",
    "SATISFACTION_SURVEY_ANSWERS": {
      "YES": "Yes",
      "NO": "No",
      "VERY_SATISFIED": "Very satisfied",
      "SATISFIED": "Satisfied",
      "NORMAL": "Normal",
      "UNSATISFIED": "Unsatisfied",
      "VERY_UNSATISFIED": "Very unsatisfied",
      "VERY_LIKELY": "Very likely",
      "VERY_UNLIKELY": "Ver unlikely"
    },
    "SUBMIT": "Submit",
    "DATA_SUBMITTED": "Information sent. Thank you for your cooperation."
  },

  "NAVBAR": {

    "BUTTON_PROCESSES": "@:PROCESSES.TITLE",

    "LANGUAGE_MENU": {
      "BUTTON": "Sprache",

      "LOCALE": {
        "EN": "Englisch",
        "ES": "Spanisch",
        "DE": "Deutsche"
      }
    },

    "MRO_SUITE_MENU": {
      "MRO_CADIZ": "Berichte",
      "MRO_CORE": "Kerndaten",
      "MRO_IBIZA": "Claims",
      "MRO_POZOBLANCO": "Vorrat",
      "MRO_UGAO": "Reparaturen",
      "MRO_CASSUM": "Benutzer",
      "MRO_ODOO": "Komponenten"
    },

    "ABOUT": "Über",
    "DISCLAIMER": "Alle Rechte vorbehalten.",
    "LOGOUT_BUTTON": "Ausloggen"
  },

  "CLAIM_LIST_HEADERS": {
    "ORDER_TYPE": "OrderType",
    "POLICY_TYPE": "PolicyType",
    "CLAIM_REF": "ClaimRef",
    "ORDER_REF": "OrderRef",
    "REQ_REF": "ReqRef",
    "CREATION_TS": "Creation TS",
    "CREATION_SER_NUM": "Creation SerNum",
    "DEVICE_DESCRIPTION": "Device Description",
    "COMMENTS": "Comments",
    "DEVICE_IN_SER_NUM": "Device In SerNum",
    "MODEL_IN": "Model In",
    "DEVICE_OUT_SER_NUM": "Device Out SerNum",
    "MODEL_OUT": "Model Out",
    "PICKUP_RESULT": "Pickup Result",
    "INSPECTION_RESULT": "Inspection Result",
    "REPAIR_RESULT": "Repair Result",
    "LAST_EVENT": "Last Event",
    "EVENT_TS": "Event TS"
  },
  "PROCESSES": {
    "TITLE": "Processes",
    "ERROR": {
      "TITLE": "Error",
      "403": "You are not authorized to perform this action",
      "UNKNOWN_ERROR": "An unknown error has appeared while trying to perform this action"
    },
    "TABLE_HEADERS": {
      "PROCESS_NAME": "Process",
      "STARTED": "Started",
      "EXECUTING": "Executing"
    },
    "STATUS": {
      "STARTED": "Started",
      "STOPPED": "Stopped",
      "EXECUTING": "Executing",
      "NOT_EXECUTING": "Not executing",
      "UNKNOWN": "Unknown status"
    },
    "ACTIONS": {
      "START": "Start",
      "STOP": "Stop",
      "FORCESHUTDOWN": "Force shutdown",
      "FORCESHUTDOWN_CONFIRMATION_1": "Are you sure that you want to shut down '",
      "FORCESHUTDOWN_CONFIRMATION_2": "'? This action may cause some memory leaks.",
      "LOADING": "Loading...",
      "STARTING": "Starting...",
      "STOPPING": "Stopping...",
      "SHUTTINGDOWN": "Forcing shutdown..."
    }
  }
};