<template>

  <div v-if="claimInfoSelected">
    <!--<claim-info-events :claim="claimInfoSelected" @statusDecided="(value) => (claimStatus = value)"></claim-info-events>-->

    <!--<div>
      <div class="padded-in">
        <div class="claim-status-container text-center">
          <i class="icon-tf-2x icon-tf-ico-warning color-blue" v-if="shouldDisplayWarningIconOnEvent" aria-hidden="true"></i>
          <big class="bold">
            <span>{{ $t("GENERICS.CURRENT_STATUS") }}</span
            >:
          </big>
          <h2 class="inline-block-element">
            {{ $t(claimLastEvent.eventDescription) }}
          </h2>
        </div>

        <div class="container-fluid">
          <div class="row">
            <div
              class="col-xs-12 claim-status-info-container"
              :class="{
                'col-md-6': pickupInfoIsDisplayed || returnInfoIsDisplayed,
                'text-center': !(pickupInfoIsDisplayed || returnInfoIsDisplayed),
              }"
            >
              <div class="inline-block-element border-right-separator event-info-icon">
                <i class="icon-tf-2x icon-tf-ico-tiempo color-blue" aria-hidden="true"></i>
              </div>
              <div class="inline-block-element">
                <span class="bold">
                  <span>{{ $t("GENERICS.DATE_TIME") }}</span
                  >:
                </span>
                <h2 class="hidden-xs inline-block-element">
                  {{ $moment(claimLastEvent.eventTS).format("DD/MM/YYYY") }} ·
                  <span class="color-medium-gray">
                    {{ $moment(claimLastEvent.eventTS).format("H:mm:ss") }}
                  </span>
                </h2>
                <span class="visible-xs visible-xs-inline-block">
                  {{ $moment(claimLastEvent.eventTS).format("DD/MM/YYYY") }} ·
                  <span class="color-medium-gray">
                    {{ $moment(claimLastEvent.eventTS).format("H:mm:ss") }}
                  </span>
                </span>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 claim-status-info-container" v-if="pickupInfoIsDisplayed">
              <div class="inline-block-element border-right-separator event-info-icon">
                <i class="icon-tf-2x icon-tf-ico-seguimiento color-blue" aria-hidden="true"></i>
              </div>
              <div class="inline-block-element">
                <span class="bold">
                  <span>{{ $t("GENERICS.PICKUP_INFO") }}</span
                  >:
                </span>
                <h3 class="inline-block-element">
                  <a :href="'https://' + claimInfoSelected.pickupInfoUrl" target="_blank" class="color-blue">{{ claimInfoSelected.pickupNetworkNode }}</a>
                  <span class="hidden color-dark-gray">({{ claimInfoSelected.pickupInfoUrl }})</span>
                </h3>
              </div>
            </div>
            <div class="col-xs-12 col-md-6 claim-status-info-container" v-if="returnInfoIsDisplayed">
              <div class="inline-block-element border-right-separator event-info-icon">
                <i class="icon-tf-2x icon-tf-ico-seguimiento color-blue" aria-hidden="true"></i>
              </div>
              <div class="inline-block-element">
                <span class="bold">
                  <span>{{ $t("GENERICS.RETURN_INFO") }}</span
                  >:
                </span>
                <h3 class="inline-block-element">
                  <a :href="'https://' + claimInfoSelected.returnInfoUrl" target="_blank" class="color-blue">{{ claimInfoSelected.returnNetworkNode }}</a>
                </h3>
                <span class="hidden color-dark-gray">({{ claimInfoSelected.returnInfoUrl }})</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="container padded-in">
        <h2 class="color-blue">
          <span>{{ $t("GENERICS.DETAILED_INFO") }}</span>
        </h2>
      </div>

      <div class="separator"></div>

      <div class="container padded-in">
        <table id="detailed-info-table" class="text-center text-left-xs text-left-sm">
          <tbody>
            <tr>
              <td class="full-block-below-md">
                <div class="inline-block-element text-left">
                  <p class="bold color-blue">
                    <span>{{ $t("GENERICS.DEVICE_DESCRIPTION") }}</span>
                  </p>
                  <p>
                    <big>{{ claimInfoSelected.deviceDesc }}</big>
                  </p>
                </div>
              </td>
              <td class="full-block-below-md">
                <div class="inline-block-element text-left">
                  <p class="bold color-blue">
                    <span>{{ $t("GENERICS.SERIAL_NUMBER") }}</span>
                  </p>
                  <p>
                    <big>{{ claimInfoSelected.serialNumber }}</big>
                  </p>
                </div>
              </td>
              <td class="full-block-below-md">
                <div class="inline-block-element text-left">
                  <p class="bold color-blue">
                    <span>{{ $t("GENERICS.CREATION_TS") }}</span>
                  </p>
                  <p>
                    <big>{{ $moment(claimLastEvent.eventTS).format("DD/MM/YYYY H:mm:ss") }}</big>
                  </p>
                </div>
              </td>
              <td class="full-block-below-md">
                <div class="inline-block-element text-left">
                  <p class="bold color-blue">
                    <span>{{ $t("GENERICS.ID_POLICY") }}</span>
                  </p>
                  <p>
                    <big>{{ claimInfoSelected.idPolicy }}</big>
                  </p>
                </div>
              </td>
              <td class="full-block-below-md">
                <div class="inline-block-element text-left">
                  <p class="bold color-blue">
                    <span>{{ $t("GENERICS.CLAIM_CODE") }}</span>
                  </p>
                  <p>
                    <big>{{ claimInfoSelected.claimCode }}</big>
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="whitespace-separator"></div>
  -->
    <claim-info-feedback :claim="claimInfoSelected"></claim-info-feedback>
  </div>
</template>

<script>
// @ is an alias to /src
//import ClaimInfoEvents from "@/components/ClaimInfoEvents.vue";
import ClaimInfoFeedback from "@/components/ClaimInfoFeedback.vue";

export default {
  name: "ClaimInfo",
  components: {
    //ClaimInfoEvents,
    ClaimInfoFeedback,
  },
  data() {
    return {
      claimInfoSelected: null,
      claimStatus: null,
    };
  },
  computed: {
    claimLastEvent() {
      return this.claimInfoSelected.claimInfoEventDTOList[this.claimInfoSelected.claimInfoEventDTOList.length - 1];
    },
    shouldDisplayWarningIconOnEvent() {
      return this.claimLastEvent.eventDescription == "PENDING_STOCK" || this.claimLastEvent.eventDescription == "PENDING_PIECES";
    },
    pickupInfoIsDisplayed() {
      return this.claimStatus == 1 && this.claimInfoSelected.pickupNetworkNode;
    },
    returnInfoIsDisplayed() {
      return this.claimStatus >= 3 && this.claimInfoSelected.returnNetworkNode;
    },
  },
  created() {
    this.findClaimInfoByHashkey();
    const navigatorLanguage = (navigator.language || navigator.userLanguage).substring(0,2);
    const found = this.$i18n.availableLocales.find(e => e == navigatorLanguage);

    if(found) {
      this.$i18n.locale = found;
    } else {
      this.$i18n.locale = this.$i18n.fallbackLocale;
    }
  },
  methods: {
    findClaimInfoByHashkey() {
      this.axios
        .get(`${process.env.VUE_APP_IBIZA_ENDPOINT}/open-api/claims/${this.$route.params.hashkey}`)
        .then((response) => {
          let data = response.data;
          this.claimInfoSelected = data;

          if (data.destinationView != "message" && data.destinationView != "info") {
            // return $location.path('/claims/' + $routeParams.hashkey + "/" + data.destinationView);
            return console.log("TODO: Debemos redirigir a otra vista: " + data.destinationView);
          }

          if (data.destinationView == "info") {
            this.fixURLs();
            this.createDocsUrls(); // TODO: Hay que crear las URLs de los documentos?
          }

          // Display message (If any)
          if (data.alertMessageType || data.alertMessage || data.alertMessageTitle) {
            this.$alert.show({ title: data.alertMessageTitle, body: data.alertMessage, type: data.alertMessageType, hasCloseButton: false });
          }
        })
        .catch(() => {
          this.$alert.show({ title: "MESSAGES.ERROR.BASIC.ERROR_TITLE", body: "MESSAGES.ERROR.BASIC.ERROR_MESSAGE_A", type: "danger", hasCloseButton: false });
        });
    },
    // Remove 'http://' or 'https://'
    fixURLs() {
      var expression = /^(http:\/\/|https:\/\/)/;
      if (this.claimInfoSelected.pickupInfoUrl != null) {
        this.claimInfoSelected.pickupInfoUrl = this.claimInfoSelected.pickupInfoUrl.replace(expression, "");
      }
      if (this.claimInfoSelected.returnInfoUrl != null) {
        this.claimInfoSelected.returnInfoUrl = this.claimInfoSelected.returnInfoUrl.replace(expression, "");
      }
    },
    createDocsUrls() {
      if (this.claimInfoSelected.claimHasDocumentList) {
        console.log("TODO: Hay que crear las URLs de los documentos?");
      }
    },
  },
};
</script>