import { createI18n } from 'vue-i18n'
import en from './en'
import es from './es'
import de from './de'

const messages = {
  en,
  es,
  de
};

const availableLanguages =  ['es','en','de']

const i18n = createI18n({
  locale: 'es', // set locale
  fallbackLocale: 'de', // set fallback locale
  messages,
  availableLanguages
});

export default i18n;
