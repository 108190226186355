export default {
  "true": "Si",
  "false": "No",

  "MESSAGES": {
    "SUCCESS": {
      "DATA_SUBMITTED_SIMPLE": "Información enviada.",
      "DATA_SUBMITTED_THANKS": "Gracias por su respuesta.",
      "DATA_SUBMITTED_SERVICE_THANKS": "Gracias por confiar en nuestro servicio.",
      "DATA_SUBMITTED_SATISFIED": "Su comentario es muy util. Continuaremos esforzandonos para continuar ofreciendo el mejor servicio."
    },

    "ERROR":{
      "DATA_SUBMITTED_UNSATISFIED": "Analizaremos su caso para identificar mejoras en nuestro servicio. Pedimos disculpas por cualquier  inconveniente causado.",
      "DATA_NOT_FOUND": "No se han encontrado datos",
      "DATA_NOT_FOUND_BY_CLIENT_SEARCH": "No se han encontrado datos con la información introducida",
      "TITLE": "Se ha producido un error",
      "ON_SAVE_CHANGES": "No se han podido guardar los cambios con la información introducida",
      "403": "No está autorizado a realizar esta acción",
      "MANDATORY_FIELDS": "Por favor, rellene todos los campos obligatorios marcados con *.",

      "BASIC":{
        "ERROR_TITLE": "La infomación solicitada no se encuentra disponible",
        "ERROR_MESSAGE_A": "Sentimos no poder proporcionarle información sobre el rma solicitado. Por favor contacte con el administrador",
        "ERROR_MESSAGE_B": "La información ya no está disponible, si lo necesita por favor póngase en contacto con el equipo de gestión del programa de seguros: info@movired.com",
        "ERROR_MESSAGE_CORONAVIRUS_DELAY": "Debido a la compleja situación causada por el COVID-19, nuestros servicios de transporte han modificado sus procedimientos y reforzado sus medidas de seguridad y desinfección, lo que, sumado a la alta demanda, puede alargar los plazos habituales de recogida y entrega de terminales. Te pedimos disculpas por los inconvenientes que esta situación pueda ocasionarte y agradecemos tu paciencia."
      }
    }
  },

  "CLAIM_CREATED": "Siniestro Creado",
  "PICKUP_PROCESS": "Dispositivo en proceso de Recogida",
  "RECEIVED_HUB": "Dispositivo recibido en Almacén",
  "PICKUP_SUCCEEDED": "Recogida realizada",
  "PICKUP_COMPLETED": "Recogida completada",
  "RECEIVED_HUB_INCIDENCE": "Dispositivo recibido con incidencia",
  "CLAIM_ACCEPTED": "Siniestro Aceptado",
  "CLAIM_REJECTED": "Siniestro Rechazado",
  "RECEIVED_LAB": "Dispositivo en Servicio Técnico",
  "REJECTED_LAB": "Dispositivo rechazado en Servicio Técnico",
  "TERMINAL_REPAIRED": "Terminal reparado",
  "ESCALATED_HIGHER_LEVEL": "Escalado a Nivel Superior",
  "UNDER_TECHNICAL_ANALYSIS": "Terminal bajo Análisis Técnico",
  "MATERIAL_REQUESTED_PROVIDER": "Material Solicitado a Proveedor",
  "PENDING_MATERIAL": "Pendiente de Material",
  "READY_DELIVERY": "Dispositivo preparado para envío",
  "RETURN_PROCESS": "Siniestro en proceso de entrega",
  "RETURN_REQUESTED": "Envío solicitado a Transporte",
  "RETURN_COMPLETED": "Dispositivo entregado a cliente",
  "PENDING_PIECES": "Pendiente de piezas, se recibirán en breve",
  "PENDING_STOCK": "Terminal no reparado. Pendiente de stock",

  "CONTACT_INFO": "Para más información contacte con el administrador",

  "GENERICS": {

    "NONE_OPT": "-- ninguno --",
    "FILTER": "Filtrar",
    "OPTIONS": "Opciones",
    "BACK": "Atrás",
    "DETAILS": "Detalles",
    "NEW": "Nuevo",
    "EDIT": "Editar",
    "SAVE": "Guardar",
    "DELETE": "Desactivar",
    "IS_ACTIVE": "Activo",
    "DELETED": "Inactivo",
    "INFO": "Información",
    "PREVIOUS": "Anterior",
    "NEXT": "Siguiente",
    "ACCEPT": "Aceptar",
    "CANCEL": "Cancelar",
    "DOWNLOAD": "Descarga",
    "RESET": "Reset",

    "GLOBAL_SEARCH": "Búsqueda general",

    "DASHBOARD": "Dashboard",
    "SEARCH": "Buscar",

    "CLAIM": "Siniestro",
    "CLAIMS": "Siniestros",
    "TOTAL_CLAIMS": "Siniestros totales",

    "EVENTS": "Eventos",
    "EVENT": "Evento",
    "CODE": "Código",
    "DESCRIPTION": "Descripción",
    "AUX_INFO": "Info. auxiliar",
    "DATE": "Fecha",

    "PICKUP_INFO": "Info. recogida",
    "RETURN_INFO": "Info. devolución",
    "POST_CODE": "Código postal",
    "TRANSACTED_BY": "Tramitado por",
    "EXTRA_INFO": "Info. adicional",
    "PENINSULA": "(PENÍNSULA)",
    "CANARY_ISLANDS": "(CANARIAS)",

    "SERIAL_NUMBER": "Número de serie",
    "CLAIM_REF": "Ref. Siniestro",
    "ORDER_REF1": "Ref. Orden",
    "ORDER_REF2": "Ref. Pedido",
    "POLICY_TYPE": "Tipo de póliza",
    "COMMENTS": "Comentarios",
    "ID_POLICY": "Póliza",
    "CREATION_TS": "Fecha creación",
    "CREATION_SN": "Nº serie creación",
    "DEV_DESCRIPTION": "Descripción dispositivo",
    "DEV_IN_SN": "Nº serie entrada",
    "MODEL_IN": "Modelo entrada",
    "DEV_OUT_SN": "Nº serie salida",
    "MODEL_OUT": "Modelo salida",
    "CLAIM_TYPE": "Tipo Orden",
    "DEVICE": "Dispositivo",
    "MANUFACTURER": "Fabricante",
    "MODELNAME": "Modelo",
    "AFTERDATE": "Evento después de",
    "BEFOREDATE": "Evento antes de",
    "ONLY_LAST_EVENT": "Filtrar por último evento",

    "NEW_CLAIM": "Nuevo Siniestro",
    "NEW_EVENT": "Nuevo Evento",
    "CLAIM_HAS_COST": "Coste Siniestro",
    "COST_TYPE": "Cost Type",
    "TOTAL_COST": "Total Cost",
    "COSTS": "Costes",
    "DOCUMENTS": "Documentos",
    "ADD_COST": "Añadir Coste",

    "SERVICE_VENDOR": "Service vendor",
    "SERVICE_TYPE": "Service type",

    "ADD_DOCUMENT": "Añadir Documento",
    "NAME": "Nombre",
    "SELECT_FILE": "Selecciona Archivo",

    "DOWNLOAD_EXCEL": "Descargar excel",

    "CLIENT_VIEW": "Vista cliente",
    "SEE_AS_CLIENT": "Ver detalles del siniestro como cliente",

    "CLAIM_STATUS": "Estado del siniestro",
    "CURRENT_STATUS": "Estado actual",
    "STATE": "Estado",
    "CLAIM_CODE": "Código de siniestro",
    "VOLUME": "Volumen",
    "CLAIM_CREATED_STATUS": "@:CLAIM_CREATED",
    "CLAIM_PICKUP_STATUS": "@:PICKUP_PROCESS",
    "CLAIM_REPAIR_STATUS": "@:RECEIVED_LAB",
    "CLAIM_READY_RETURN_STATUS": "@:READY_DELIVERY",
    "CLAIM_DELIVERED_STATUS": "@:RETURN_COMPLETED",

    "CLIENT_INFO": "Info. cliente",
    "CLIENT_NAME": "Nombre",
    "PHONE_NUMBER": "Teléfono",
    "EMAIL": "Email",
    "ADDRESS": "Dirección",
    "CITY": "Ciudad",
    "PROVINCE": "Provincia",
    "COUNTRY": "País",
    "TAX_CODE": "CIF/NIF",

    "CLAIM_TRACKING": "Seguimiento del Siniestro",
    "DATE_TIME": "Fecha y hora",
    "DETAILED_INFO": "Información detallada",
    "DEVICE_DESCRIPTION": "Descripción del dispositivo",
    "DAYS": "días",

    "CLAIM_LIST_STATUS": "Claim status list",
    "ADD_EVENT": "Añadir evento",

    "OPERATIONS": "Operaciones",
    "REPAIR_VALIDATION": "Validar reparación",
    "REBOUND_VALIDATION": "Validar rebote",

    "SATISFACTION_SURVEY": "Encuesta de satisfacción",
    "SATISFACTION_SURVEY_QUESTIONS": {
      "QUESTION_1": "¿Le ha sido útil la información recibida durante el proceso?",
      "QUESTION_1_COMMENTS": "Nos gustaría saber cómo mejorar la información que le damos para ofrecerle un mejor servicio",
      "QUESTION_2": "¿Está satisfecho con el servicio prestado durante el siniestro de su terminal?",
      "QUESTION_2_COMMENTS": "Nos gustaría saber más sobre su experiencia y opinión para ofrecerle un mejor servicio",
      "QUESTION_3": "¿Cómo es de probable que recomiende Seguro Móvil a un familiar o amigo?"
    },
    "SATISFACTION_SURVEY_MESSAGE_USAGE": "* Su comentario podrá ser publicado en nuestra página web, pero en ningún caso serán publicados sus datos personales",
    "SATISFACTION_SURVEY_PRIVACY_POLICY_MESSAGE": "Con el envío de esta encuesta confirmo que he leído y acepto la",
    "SATISFACTION_SURVEY_PRIVACY_POLICY": "Política de Privacidad",
    "SATISFACTION_SURVEY_ANSWERS": {
      "YES": "Si",
      "NO": "No",
      "VERY_SATISFIED": "Muy satisfecho",
      "SATISFIED": "Satisfecho",
      "NORMAL": "Normal",
      "UNSATISFIED": "Insatisfecho",
      "VERY_UNSATISFIED": "Muy insatisfecho",
      "VERY_LIKELY": "Definitivamente lo recomendaría",
      "VERY_UNLIKELY": "Muy improbable"
    },
    "SUBMIT": "Enviar",
    "DATA_SUBMITTED": "Información enviada. Gracias por su colaboración.",
    "DATA_SUBMITTED_SIMPLE": "Información enviada.",
    "DATA_SUBMITTED_THANKS": "Gracias por su respuesta.",
    "DATA_SUBMITTED_MOVISTAR_THANKS": "Gracias por confiar en Seguro Móvil Movistar.",
    "DATA_SUBMITTED_UNSATISFIED": "Vamos a analizar su caso con el objetivo de identificar mejoras en nuestro servicio. Rogamos disculpe las posibles molestias ocasionadas.",
    "DATA_SUBMITTED_SATISFIED": "Su comentario nos es de mucha ayuda. Seguiremos esforzándonos para seguir ofreciendo el mejor servicio.",

    "ADD_COMMENT": "Añadir comentario",
    "CLOSE": "Cerrar",
    "WROTE_COMMENT": "escribió",
    "THERE_ARE_COMMENTS": "Existen comentarios sobre este siniestro",
    "NOTES": "Notas",

    "TRANSPORT_SERVICE": "Servicio de transporte",
    "TRANSPORT_OFFICE": "Oficina de transporte",
    "TRANSPORT_REF": "Referencia",
    "TRANSPORT_AWB": "Transporte AWB",

    "VALIDATE": "Validar",
    "REJECT": "Rechazar",

    "CONFIRM_CHANGES_ASK": "¿Está seguro de que quiere aplicar los cambios?",
    "CONFIRM_SUBMIT": "Confirmar cambios",

    "NO_CLAIMS_SELECTED": "No hay siniestros seleccionados",
    "NO_CLAIMS_SELECTED_FOR_VALIDATE": "No hay siniestros seleccionados para su validación.",
    "NO_CLAIMS_SELECTED_FOR_REJECT": "No hay siniestros seleccionados para su rechazo.",
    "NO_CLAIMS_AVAILABLE_FOR_VALIDATE": "No hay siniestros pendientes para validar",
    "NO_REBOUND_AVAILABLE_FOR_VALIDATE": "No hay rebotes pendientes para validar",

    "VIEW_IMAGE": "Ver imagen",
    "LAST_DATE": "Fecha último evento",
    "CLAIM_PREV_BOUNCE": "Siniestro pre-rebote"

  },

  "NAVBAR": {

    "BUTTON_PROCESSES": "@:PROCESSES.TITLE",

    "LANGUAGE_MENU": {
      "BUTTON": "Idioma",

      "LOCALE": {
        "EN": "Inglés",
        "ES": "Castellano",
        "DE": "Alemán"
      }
    },

    "MRO_SUITE_MENU": {
      "MRO_CADIZ": "Reportes",
      "MRO_CORE": "Datos maestros",
      "MRO_IBIZA": "Siniestros",
      "MRO_POZOBLANCO": "Stock",
      "MRO_UGAO": "Reparaciones",
      "MRO_CASSUM": "Usuarios",
      "MRO_ODOO": "Componentes"
    },

    "ABOUT": "Acerca de",
    "DISCLAIMER": "Todos los derechos reservados.",
    "LOGOUT_BUTTON": "Salir"
  },

  "CLAIM_LIST_HEADERS": {
    "ORDER_TYPE": "TipoOrden",
    "POLICY_TYPE": "TipoPóliza",
    "CLAIM_REF": "RefSiniestro",
    "ORDER_REF": "RefOrden",
    "REQ_REF": "RefPedido",
    "CREATION_TS": "Fecha Creación",
    "CREATION_SER_NUM": "NumSer Creación",
    "DEVICE_DESCRIPTION": "Descripción Dispositivo",
    "COMMENTS": "Comentarios",
    "DEVICE_IN_SER_NUM": "NumSer Dispositivo Entrada",
    "MODEL_IN": "Modelo Entrada",
    "DEVICE_OUT_SER_NUM": "NumSer Dispositivo Salida",
    "MODEL_OUT": "Modelo Salida",
    "PICKUP_RESULT": "Resultado Recogida",
    "INSPECTION_RESULT": "Resultado Inspección",
    "REPAIR_RESULT": "Resultado Reparación",
    "LAST_EVENT": "Último Evento",
    "EVENT_TS": "Fecha Evento"
  },

  "PROCESSES": {
    "TITLE": "Procesos",
    "ERROR": {
      "TITLE": "Error",
      "403": "No está autorizado para realizar esta acción",
      "UNKNOWN_ERROR": "Se ha producido un error desconocido mientras intentaba realizar esta acción"
    },
    "TABLE_HEADERS": {
      "PROCESS_NAME": "Proceso",
      "STARTED": "Iniciado",
      "EXECUTING": "En ejecución"
    },
    "STATUS": {
      "STARTED": "Iniciado",
      "STOPPED": "Parado",
      "EXECUTING": "En ejecución",
      "NOT_EXECUTING": "No ejecutandose",
      "UNKNOWN": "Estado desconocido"
    },
    "ACTIONS": {
      "START": "Iniciar",
      "STOP": "Parar",
      "FORCESHUTDOWN": "Forzar parada",
      "FORCESHUTDOWN_CONFIRMATION_1": "¿Está seguro de que desea forzar la parada de '",
      "FORCESHUTDOWN_CONFIRMATION_2": "'? Esta acción puede causar memory leaks.",
      "LOADING": "Cargando...",
      "STARTING": "Iniciando...",
      "STOPPING": "Parando...",
      "SHUTTINGDOWN": "Forzando parada..."
    }
  }
};