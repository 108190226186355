// @ is an alias to /src
import '@/assets/css/bootstrap.css';
import '@/assets/css/main.css';
import '@/assets/css/main-basic.css';
import '@/assets/css/flags.css';
import '@/assets/css/telefonicaIconsTracking.css';
import "../node_modules/font-awesome/css/font-awesome.min.css";

import * as Vue from 'vue'
import axios from 'axios'
import moment from 'moment'
import VueAxios from 'vue-axios'
import App from './App.vue'
import router from './router'
import i18n from './i18n'
import ClickOutside from "./directives/ClickOutside.js"
import AlertMessageService from "./components/AlertMessage/AlertMessageService.js"

const app = Vue.createApp(App);
app.config.globalProperties.$moment = moment;
app.use(router);
app.use(VueAxios, axios);
app.use(i18n);
app.use(AlertMessageService);
//Custom Directives
app.directive("click-outside", ClickOutside);
app.mount('#app');