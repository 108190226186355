<template>
    <a v-click-outside="clickOutSideAndHideDropDown" @click="showOrHideDropDown()" id="showInputButton" role="button" class="dropdown-toggle" aria-haspopup="true" aria-expanded="false" data-toggle="dropdown-menu" :title="$t('NAVBAR.LANGUAGE_MENU.BUTTON')">
        <span class="flag" :class="'flag-' + $i18n.locale"></span>
    </a>
    <div id="dropdown" class="dropdown-content">
        <a v-for="language in $i18n.availableLocales" :key="language" @click.prevent="this.$i18n.locale = language">
            <span class="flag" :class="'flag-' + language"></span>
            <span> </span>
            <span>{{' ' + $t("NAVBAR.LANGUAGE_MENU.LOCALE." + language.toUpperCase()) }}</span>
        </a>
    </div>
</template>


<style scoped>

    .dropdown-content {
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        z-index: 1;
        right: 1px;
    }

    .dropdown-content a {
        color: black;
        padding: 12px 16px;
        text-decoration: none;
        display: block;
    }

    .dropdown-content a:hover {background-color: #f1f1f1}

    .dropdown:hover .dropdown-content {
        display: block;
    }

    .dropdown:hover .dropbtn {
        background-color: #3e8e41;
    }

</style>

<script>
import { createPopper } from '@popperjs/core';

export default {

    name: "LanguageSelector",

    mounted() {
        document.querySelector('#dropdown').style.visibility = "hidden"
        const popcorn = document.querySelector('#showInputButton');
        const tooltip = document.querySelector('#dropdown');
        createPopper(popcorn, tooltip, {
            placement: 'bottom',
        });
    },

    methods: {
        showOrHideDropDown() {
            let elementDropdown = document.querySelector('#dropdown');
            if (elementDropdown) {
                if (elementDropdown.style.visibility === "hidden"){
                    elementDropdown.style.visibility = "visible"
                }else{
                    elementDropdown.style.visibility = "hidden"
                }
            }
        },

        clickOutSideAndHideDropDown(){
            let elementDropdown = document.querySelector('#dropdown');
            if (elementDropdown) {
                elementDropdown.style.visibility = "hidden"
            }

        }
    },
};
</script>