import AlertMessage from "@/components/AlertMessage/AlertMessage.vue";
import AlertMessageEventBus from "@/components/AlertMessage/AlertMessageEventBus.js"

export default {
  install(app) {
    const AlertMessageService = {
      show: (message) => {
        AlertMessageEventBus.emit('show', message);
      },
      hide: (group) => {
        AlertMessageEventBus.emit('hide', group);
      }
    };
    app.config.globalProperties.$alert = AlertMessageService;
    app.component("alert-message", AlertMessage);
  }
}