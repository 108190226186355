<template>
  <div class="page-header" id="page-header-basic">
    <h2 class="padded-in">
      <span>{{ $t("GENERICS.CLAIM_TRACKING") }}</span> &gt; <small class="inline-block-element color-dark-gray">{{ $moment(claim.eventTS).format("DD/MM/YYYY") }}</small> &gt;
      <small class="inline-block-element color-black bold">{{ claim.deviceDesc }}</small>
    </h2>
  </div>
  <div class="separator visible-xs"></div>

  <div>
    <div class="padded-in">
      <div class="container-fluid container-justified-blocks">
        <div class="dotted-background"></div>
        <div style="display: flex; justify-content: space-between">
          <div class="justified-block icon-container text-center claim-status-pending" v-for="(event, index) in claimStatusEvents" :key="event.eventName" :class="{ 'claim-status-done': claimStatus >= index }">
            <div class="claim-status-icon">
              <h1 class="step-header hidden-sm hidden-xs">{{ index + 1 }}</h1>
              <h2 class="step-header visible-sm-inline">{{ index + 1 }}</h2>
              <h4 class="step-header visible-xs-inline">{{ index + 1 }}</h4>
              <span class="icon-tf-lg"><i class="icon-tf-4x icon-tf-3x-sm icon-tf-2x-xs background-white" :class="event.icon" aria-hidden="true"></i></span>
            </div>
            <div class="claim-status-text hidden-xs">
              <span>{{ $t(event.eventName) }}</span
              ><br />
              <span>{{ event.date ? $moment(event.date).format("DD/MM/YYYY") : "" }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ClaimInfoEvents",
  props: ["claim"],
  emits: ["statusDecided", "status-decided"], // TODO: Hay un bug con el camelCase/kebab-case de los eventos, así que lo indicamos de ambas formas para que no salte el warning en consola. Habría que dejarlo como 'status-decided'. Más info: https://github.com/vuejs/vue-next/issues/2540
  data() {
    return {
      claimStatus: null,
      claimStatusEvents: [
        { eventName: "CLAIM_CREATED", icon: "icon-tf-ico-siniestro-creado", date: null }, // ctrl.claimStatus = 0
        { eventName: "PICKUP_PROCESS", icon: "icon-tf-ico-recogida", date: null }, // ctrl.claimStatus = 1
        { eventName: "RECEIVED_LAB", icon: "icon-tf-ico-servicio-tecnico", date: null }, // ctrl.claimStatus = 2
        { eventName: "READY_DELIVERY", icon: "icon-tf-ico-pendiente-envio", date: null }, // ctrl.claimStatus = 3
        { eventName: "RETURN_COMPLETED", icon: "icon-tf-ico-envio", date: null }, // ctrl.claimStatus = 4
      ],
    };
  },
  mounted() {
    this.mapEvents();
  },
  methods: {
    mapEvents() {
      // Map events, set events' dates and set ctrl.claimStatus
      for (var i = this.claim.claimInfoEventDTOList.length - 1; i >= 0; i--) {
        var event = this.claim.claimInfoEventDTOList[i];
        var eventEquivalenceID = null;

        // Get event equivalence
        switch (event.eventDescription) {
          case "CLAIM_CREATED":
            eventEquivalenceID = 0;
            break;
          case "PICKUP_PROCESS":
          case "PICKUP_SUCCEEDED":
          case "PICKUP_COMPLETED":
            eventEquivalenceID = 1;
            break;
          case "RECEIVED_HUB":
          case "RECEIVED_LAB":
          case "ESCALATED_HIGHER_LEVEL":
          case "UNDER_TECHNICAL_ANALYSIS":
          case "MATERIAL_REQUESTED_PROVIDER":
          case "PENDING_PIECES":
          case "PENDING_STOCK":
            eventEquivalenceID = 2;
            break;
          case "REJECTED_LAB":
          case "TERMINAL_REPAIRED":
          case "READY_DELIVERY":
          case "RETURN_REQUESTED":
            eventEquivalenceID = 3;
            break;
          case "RETURN_COMPLETED":
            eventEquivalenceID = 4;
            break;
          default:
            continue;
        }

        // Set current status
        if (!this.claimStatus) {
          this.claimStatus = eventEquivalenceID;
          this.$emit("status-decided", eventEquivalenceID);
        }

        // Finally, set event date. (Iteration done in reverse order, oldest date is set)
        this.claimStatusEvents[eventEquivalenceID].date = event.eventTS;
      }
    },
  },
};
</script>