<template>
  <div class="modal-backdrop" v-show="showPopup" @click="closePopup()" style="background-color: rgba(51, 51, 51, 0.3)">
    <div class="modal-dialog" :style="'margin-top:150px;width:' + popupWidth + 'px;min-width:450px'">
      <div class="modal-content" style="height: auto; min-height: 230px; overflow: hidden">
        <div class="modal-header">
          <button type="button" class="close" @click="closePopup()" aria-hidden="true">&times;</button>
          <h4 class="modal-title">{{ title }}</h4>
        </div>
        <div class="modal-body" style="padding: 20px 15px">
          <ul class="col-md-12">
            <img :src="require(`@/${logoUrl}`)" style="height: 80px" class="pull-right" /><br />
            <span class="h2">{{ appName }}</span
            ><br />
            <span>v. {{ version }}</span>
            <br /><br />
          </ul>
          <ul v-if="content" class="col-md-12" style="border-top: 1px solid #e5e5e5; padding-top: 10px; text-align: center">
            <span class="h6" style="color: #767676">{{ content }}</span>
          </ul>
          <ul class="col-md-12" style="border-top: 1px solid #e5e5e5; padding-top: 10px">
            <a v-if="website" :href="'https://' + website" target="_blank">{{ website }}</a>
            <span v-if="company" class="h6 pull-right" style="margin-top: 5px">&copy; {{ company }}</span>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AboutPopup",
  props: {
    logoUrl: String,
    version: String,
    showPopup: Boolean,
    popupWidth: Number,
    title: String,
    content: String,
    appName: String,
    company: String,
    website: String,
  },
  methods: {
    closePopup() {
      this.$emit("update:show-popup", false);
    },
  },
};
</script>