<template>
  <div>
    <ul class="nav navbar-nav navbar-right" style="font-size: large">
      <!-- LANGUAGE DROPDOWN -->
      <li class="dropdown">
        <LanguageSelector></LanguageSelector>
      </li>
      <!-- ABOUT POPUP BUTTON -->

      <about-popup
        logo-url="assets/images/LogoMovistar02.png"
        :version="appVersion"
        v-model:show-popup="showAbout"
        :popup-width="500"
        :title="$t('NAVBAR.ABOUT')"
        :content="$t('NAVBAR.DISCLAIMER')"
        app-name="MRO_Ibiza"
        company="Movired 2000 S.L."
        website="www.movired.com"
      ></about-popup>

      <li>
        <a @click.prevent="openAbout()" :title="$t('NAVBAR.ABOUT')">
          <span class="glyphicon glyphicon-info-sign" style="color: inherit" aria-hidden="true"></span>
        </a>
      </li>

      <!-- LOGO -->
      <a class="navbar-brand navbar-right" target="_blank" href="https://www.movired.com" ng-click="main.logoClick()">
        <img class="logo" src="@/assets/images/LogoMovistar01.png" />
      </a>
    </ul>
  </div>
</template>

<script>
import LanguageSelector from "./LanguageSelector.vue";
import AboutPopup from "./AboutPopup.vue";
export default {
  name: "NavBar",
  components: { LanguageSelector, AboutPopup },
  data() {
    return {
      showAbout: false,
    };
  },
  methods: {
    openAbout() {
      this.showAbout = true;
    },
  },
  computed: {
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
};
</script>